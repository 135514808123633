import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import {detectMob} from '@src/Helper';

function NotFoundPage() {
  const [id, setId] = useState('not-found')

  useEffect(() => {
    if(detectMob()) {
      setId('m-not-found');
    }
  }, [])

  return (
    <div id={id}>
      <section className="section-200">
        <div className="container d-flex flex-column text-center not-found-container">
          <div className="title text-navy-blue mb-3"><span className="text-400">404 | </span>Not Found</div>
          <div className="subcaption text-meteoroid-purple mb-5">
            The page you're looking for doesn't exist or has been relocated.
          </div>
          <div>
            <Link className="button button-mafia-primary" to="/">
              Back to Home
            </Link>
          </div>
        </div>
      </section>
    </div>
  )
}

export default NotFoundPage
